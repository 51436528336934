
import {defineComponent, onMounted, ref, reactive, toRefs} from 'vue';
import type Node from 'element-plus/es/components/tree/src/model/node'
import {apiResponseErrorHandle} from "@/composables/errorHandler";
import {ElTree} from 'element-plus/es/components/tree';
import {ElMessage} from "element-plus/es";
import {ResizeObserver} from '@juggle/resize-observer';
import useNoteHistories from "@/composables/useNoteHistories";
import useShowNotePageData from '@/composables/useShowNotePageData';
import {Diff2HtmlUI} from "diff2html/lib-esm/ui/js/diff2html-ui";
import {Diff2HtmlUIConfig} from "diff2html/lib-esm/ui/js/diff2html-ui-base";
import 'diff2html/bundles/css/diff2html.min.css';
import 'diff2html/bundles/js/diff2html-ui.min.js'

export default defineComponent({
  name: 'Index',
  components: {},
  setup() {
    const {getNoteHistories, getNoteHistoryUnifiedDiff, restoreNoteHistory} = useNoteHistories();
    const {parseNoteId} = useShowNotePageData();

    const state: {
      overNodeKey: string,
      volatileOverNodeKey: string | null,
      nodeOpMoreOpen: boolean,
      diffOutputFormat: "line-by-line" | "side-by-side" | undefined,
      diffOutputFormatRadio: "分屏" | "合并",
      diffString: string
    } = reactive({
      overNodeKey: '',
      volatileOverNodeKey: null,
      nodeOpMoreOpen: false,
      diffOutputFormat: 'line-by-line',
      diffOutputFormatRadio: "合并",
      diffString: ''
    });

    interface Tree {
      id: number
      key: string
      name: string
      leaf?: boolean
      class?: string
    }

    const props = {
      label: 'label',
      key: 'key',
      children: 'zones',
      isLeaf: 'leaf',
      class: 'custom-tree-node'
    }

    const treeRef = ref<InstanceType<typeof ElTree>>()
    const id = ref<number | null>(null)

    id.value = parseNoteId();

    onMounted(() => {
      listenToDragAside()

      const ro = new ResizeObserver(adaptTreeWidth);
      const tree = document.getElementById('tree');
      if (tree) {
        ro.observe(tree)
      }
    })

    const render = () => {
      const targetElement = document.getElementById('myDiffElement');
      if (!targetElement) {
        return;
      }
      const configuration: Diff2HtmlUIConfig = {
        drawFileList: false,
        fileListToggle: false,
        fileListStartVisible: false,
        fileContentToggle: false,
        matching: 'lines',
        diffStyle: 'char',
        outputFormat: state.diffOutputFormat,
        synchronisedScroll: true,
        highlight: true,
        renderNothingWhenEmpty: false,
        stickyFileHeaders: false
      };
      const diff2htmlUi = new Diff2HtmlUI(targetElement, state.diffString, configuration);
      diff2htmlUi.draw();
      diff2htmlUi.highlightCode();
    }

    const loadNode = (node: Node, resolve: (data: Tree[]) => void) => {
      if (id.value && !node.data.id) {
        getNoteHistories(id.value).then(function (response) {
          const data = makeTreeData(response.data);
          resolve(data)
          data.length > 0 && loadFirstNode(data.at(0))
        }).catch(apiResponseErrorHandle('获取数据失败', () => {
          node.loading = false
        }));
      }
    }

    const loadFirstNode = (data: Tree | undefined) => {
      if (data) {
        get(id.value, data.id);
        const node: Node | undefined = treeRef.value?.getNode(data.key)
        if (node) {
          treeRef.value?.setCurrentNode(node, true)
        }
      }
    }

    const makeTreeData = (responseData: any): Tree[] => {
      return responseData.noteHistories.map((v: any) => {
        return {
          ...v,
          label: v.createdAt + " " + v.name,
          key: `history_${v.id}`,
          leaf: true
        }
      });
    }

    const overNode = (node: any) => {
      state.volatileOverNodeKey = node.key;
      state.overNodeKey = node.key;
    }

    let clearOverNodeTimeout: any
    const clearOverNode = () => {
      state.volatileOverNodeKey = null
      clearTimeout(clearOverNodeTimeout)
      clearOverNodeTimeout = setTimeout(() => {
        if (!state.nodeOpMoreOpen && !state.volatileOverNodeKey) {
          state.overNodeKey = '';
        }
      }, 500)
    }

    const restore = (node: Node) => {
      restoreNoteHistory(node.data.noteId, node.data.id).then(function (response) {
        ElMessage({
          showClose: true,
          message: '版本恢复成功',
          type: 'success',
        });
      }).catch(apiResponseErrorHandle('版本恢复失败'));
    }

    const nodeClick = async (data: any, node: Node, event: Event) => {
      get(id.value, data.id);
    }

    const get = (noteId: number | null, historyId: number) => {
      noteId && historyId && getNoteHistoryUnifiedDiff(noteId, historyId).then(function (response) {
        state.diffString = response.data.unifiedDiff
        render()
      }).catch(apiResponseErrorHandle('获取数据失败'))
    }

    const nodeOpMoreVisibleChange = (visible: boolean) => {
      state.nodeOpMoreOpen = visible
    }

    const onFormatClick = async (label: '分屏' | '合并') => {
      if (label === '分屏') {
        state.diffOutputFormat = "side-by-side"
      } else {
        state.diffOutputFormat = "line-by-line"
      }
      render();
    }

    const listenToDragAside = () => {
      const MIN_WIDTH_ASIDE = 130;
      const MIN_WIDTH_MAIN = 400;
      const DRAG_BAR_WIDTH = 8;
      const dragBar: any = document.getElementById('drag-bar');
      const aside: any = document.getElementById('aside');
      const tree: any = document.getElementById('tree');
      const main: any = document.getElementById('main');
      const container: any = document.getElementById('container');

      const onMove = (startPosX: number, endPosX: number) => {
        let currentAsideWidth = dragBar.left + (endPosX - startPosX) + dragBar.offsetWidth;

        if (currentAsideWidth < MIN_WIDTH_ASIDE) {
          currentAsideWidth = MIN_WIDTH_ASIDE;
        }

        const asideWidthMax = container.clientWidth - MIN_WIDTH_MAIN;
        if (currentAsideWidth > asideWidthMax) {
          currentAsideWidth = asideWidthMax;
        }

        aside.style.width = currentAsideWidth + 'px';
        tree.style.width = (currentAsideWidth - DRAG_BAR_WIDTH) + 'px';
        main.style.width = (container.clientWidth - currentAsideWidth) + 'px';
        dragBar.style.left = currentAsideWidth;
      }

      if (dragBar) {
        dragBar.onmousedown = (e: any) => {
          const startPosX = e.clientX;
          dragBar.left = dragBar.offsetLeft;
          document.onmousemove = (e) => {
            onMove(startPosX, e.clientX);
          }
          document.onmouseup = (e) => {
            document.onmousemove = null;
            document.onmouseup = null;
            dragBar.releaseCapture && dragBar.releaseCapture();
          }
          dragBar.setCapture && dragBar.setCapture();
          //设置鼠标捕获
          return false;
        }

        dragBar.ontouchstart = (e: any) => {
          const startPosX = e.changedTouches?.item(0)?.clientX;
          dragBar.left = dragBar.offsetLeft;
          document.ontouchmove = (e) => {
            const endPosX = e.changedTouches?.item(0)?.clientX;
            endPosX && onMove(startPosX, endPosX);
          }
          document.ontouchend = (e) => {
            document.ontouchmove = null;
            document.ontouchend = null;
          }
          return false;
        }
      }
    }

    const adaptTreeWidth = () => {
      const tree = document.getElementById('tree');
      const elTree = document.getElementById('el-tree');
      if (tree && elTree) {
        elTree.style.minWidth = tree.clientWidth + 'px';
      }
    }

    return {
      ...toRefs(state),
      loadNode,
      overNode,
      clearOverNode,
      restore,
      nodeClick,
      nodeOpMoreVisibleChange,
      onFormatClick,
      props,
      treeRef,
      id
    }
  }
});
